var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"document-preview"},[(_vm.fileBlobUrl)?_c('div',[(_vm.showPdfPreview)?_c('div',{staticClass:"document-preview__pdf-wrapper",style:(("height:" + _vm.height + "px")),on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }}}},[(_vm.idCustom == 'vuePdfApp1' && false)?_c('vue-pdf-app',{attrs:{"pdf":_vm.fileLink,"id":_vm.idCustom,"config":{
          sidebar: false,
          toolbar: {
            toolbarViewerLeft: {
              findbar: false,
              previous: false,
              next: false,
              pageNumber: false,
            },
            toolbarViewerMiddle: false,
            toolbarViewerRight: {
              openFile: false,
              viewBookmark: false,
            }
          }
         }},on:{"open":_vm.openHandler,"after-created":_vm.afterCreatedHandler,"pages-rendered":_vm.pagesRendered}}):_vm._e(),(_vm.idCustom == 'vuePdfApp1' && !!_vm.fileLink)?_c('pdf-js',{attrs:{"path":'/lib/pdfjs-4.0.189-dist/web/viewer.html',"fileName":'invoicesample.pdf',"fileLink":_vm.fileLink,"height":_vm.height,"htmlId":_vm.documentType}}):_vm._e(),(_vm.idCustom == 'vuePdfApp2' && !!_vm.fileLink)?_c('pdf-js',{attrs:{"path":'/lib/pdfjs-4.0.189-dist/web/viewer.html',"fileName":'invoicesample.pdf',"fileLink":_vm.fileLink,"height":_vm.height,"htmlId":_vm.documentType}}):_vm._e()],1):_vm._e()]):_c('div',[_c('v-progress-circular',{attrs:{"size":70,"width":7,"indeterminate":"","color":"primary"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
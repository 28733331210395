import { render, staticRenderFns } from "./document-preview.html?vue&type=template&id=3f64eb3c&scoped=true&"
import script from "./document-preview.ts?vue&type=script&lang=ts&"
export * from "./document-preview.ts?vue&type=script&lang=ts&"
import style0 from "./document-preview.scss?vue&type=style&index=0&id=3f64eb3c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f64eb3c",
  null
  
)

/* custom blocks */
import block0 from "./document-preview.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fviews%2Fhome%2Fdocument-preview%2Fdocument-preview.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VProgressCircular})

import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import VuePdfApp from 'vue-pdf-app';
import 'vue-pdf-app/dist/icons/main.css'; // import this to use default icons for buttons
import { namespace } from 'vuex-class';
// import FileUtils from '@/shared/utils/fileUtils';
import { Logger } from 'fsts';
import PdfViewer from 'vue-pdf-app';
import { Route } from 'vue-router';
import zrDoc, { ZRDocument } from '@/shared/model/zrDocument';
import inv, { Invoice } from '@/shared/model/invoice';
import pdfJs from './pdfjs.vue';
import chvouch, { ChangeVoucher } from '@/shared/model/changeVoucher';

const logger = new Logger('document-preview');

const authModule = namespace('auth');
const zrDocumentModule = namespace('zrDocument');

@Component({ name: 'document-preview', components: { VuePdfApp, pdfJs } })
export default class DocumentPreview extends Vue {
  @Prop({ default: '', type: String })
  private recordId!: string;
  @Prop({ default: () => zrDoc.parse({}) })
  private document!: ZRDocument;
  @Prop({ default: () => inv.parse({}) })
  private invoice!: Invoice;
  @Prop({ default: () => chvouch.parse({}) })
  private voucher!: ChangeVoucher;
  @Prop({ default: '', type: String })
  private documentType!: string;
  @Prop({ default: 300, type: Number })
  private height!: number;

  @zrDocumentModule.Action('getAzureFile')
  private actionGetAzureFile!: any;

  //#endregion

  private idCustom = 'vuePdfApp1';

  get showPdfPreview() {
    // return this.isFileInfoLoaded && FileUtils.isPdf(this.getFile.documentName);
    return !!this.fileLink;
  }

  get noPreview() {
    return !this.showPdfPreview;
  }

  get fileLink() {
    return this.fileBlobUrl;
  }

  get pageHeightMinusHeader() {
    return document.querySelector('.v-main')?.clientHeight + 'px';
  }

  private fileBlobUrl: string = ''; //'http://localhost:5500/01.pdf';

  private async getBelegeUserFile() {
    var promiseAll: any = [];
    //     // fetch('https://www.orimi.com/pdf-test.pdf',{mode: 'cors'})
    //     // var x = await fetch('http://localhost:5500/pdf-test.pdf',{
    //     var x = await fetch('http://cors-anywhere.herokuapp.com/url=https://www.orimi.com/pdf-test.pdf', {
    //       method: 'GET',
    //       mode: 'cors',
    //       headers: new Headers({
    //         // "mode": "no-cors",

    //         'Access-Control-Allow-Origin': '*', //'https://www.orimi.com',
    //         'Access-Control-Allow-Methods': 'HEAD, POST, PUT, DELETE, GET, OPTIONS',
    //         'Access-Control-Request-Method': '*',
    //         'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
    //         'Access-Control-Allow-Credentials': 'true',
    //         'content-disposition': 'attachment',

    //         // config.headers['Access-Control-Allow-Methods'] = 'GET'
    //         // config.headers['Access-Control-Allow-Methods'] = 'POST, PUT, DELETE, GET, OPTIONS'
    //         // config.headers['Access-Control-Request-Method'] = '*'
    //         // config.headers['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept, Authorization'
    //       }),
    //     });
    // console.log('x :>> ', x);

    // let sss = await x.blob();
    // console.log('sss :>> ', sss);
    // // this.fileBlobUrl = window.URL.createObjectURL(sss);
    // console.log('this.fileBlobUrl :>> ', this.fileBlobUrl);
    // .then( async (result:any) => {
    //   console.log('result fectch :>> ', result);
    //   console.log(result);
    //   this.fileBlobUrl = window.URL.createObjectURL(result.body);
    //   // await result.blob();
    //   console.log('result.blob() :>> ', result.blob());
    // }) .then( (blob:any) => {
    //   console.log('blob 2nd then :>> ', blob);
    //   // this.fileBlobUrl = window.URL.createObjectURL(blob);
    //   // window.location.assign(file);
    // });
    // await this.actionGetDocument(this.documentId);
    // logger.debug(`fileId: ${this.fileId}`);
    // if (this.fileId)
    //   promiseAll.push(
    //     this.actionGetFile(this.fileId).catch((err: any) => {
    //       logger.error(err);
    //     }),
    //     this.actionDownloadFile(this.fileId)
    //       .then((result: Blob) => {
    //         this.fileBlobUrl = URL.createObjectURL(result);
    //       })
    //       .catch((err: any) => {
    //         logger.error(err);
    //       })
    //   );

    const payload = {
      documentType: this.documentType,
      recordId: this.document.recordID,
      dmsNr: '',
    };

    await this.actionGetAzureFile(payload)
      .then((result: any) => {
        console.log('actionGetAzureFile result :>> ', result);
        if (result?.message?.toString().includes('failed')) {
          this.$emit('showBackupImage', 'Belege');
        } else {
          this.fileBlobUrl = URL.createObjectURL(result);
          this.$emit('document:loaded');
        }
      })
      .catch((err: any) => {
        logger.error(err);
      });

    Promise.all(promiseAll);
  }

  beforeRouteEnter(to: Route, from: Route, next: any) {
    to.params.urlBeforeDocPreview = from.fullPath; // (ED-1060) need `urlBeforeDocPreview` to know if we return to the same folder from `document-preview` route (if YES keep the paging info, if NO reset paging to page 1)
    next((vm: any) => {});
  }

  beforeRouteUpdate(to: Route, from: Route, next: any) {
    // this.getDocumentEditSession(to.params['documentId']).then((response: DocumentEditSession) => {
    //   if (response.isCurrentUser || !response) {
    //     this.deleteDocumentEditSession(this.documentId);
    //     next();
    //   } else {
    //     let user = response.fullUserName;
    //     this.$confirm
    //       .open(
    //         `${this.$t('dialogs.already_opened.title')}`,
    //         `${this.$t('dialogs.already_opened.message', {
    //           0: response.documentId,
    //           1: response.documentId,
    //           2: user,
    //         })}`,
    //         {
    //           cancelText: this.$t('dialogs.already_opened.cancel'),
    //           okText: this.$t('dialogs.already_opened.open'),
    //         }
    //       )
    //       .then(async (response: any) => {
    //         if (response) {
    //           this.deleteDocumentEditSession(this.documentId);
    //           next();
    //         } else {
    //           next(false);
    //         }
    //       });
    //   }
    // });
  }

  beforeRouteLeave(to: Route, from: Route, next: any) {
    // this.deleteDocumentEditSession(this.documentId);
    // next();
  }

  async mounted() {
    // let editSession: DocumentEditSession = documentEditSession.parse({ documentId: this.documentId });
    // this.updateDocumentEditSession(editSession);
    // await this.getActionLogsQa({ searchData: { documentId: this.documentId } });
  }

  async created() {
    console.log('Doc preview created this.documentType :>> ', this.documentType);
    // console.log('process.env.NODE_ENV :>> ', process.env.NODE_ENV);
    // logger.log('process.env.VUE_KEEPTIME_BACKEND_API_URL :>> ', process.env.VUE_KEEPTIME_BACKEND_API_URL); // used in src\shared\backend\index.ts (line:9 ~ `instance` variable)

    if (this.documentType == 'Belege') {
      await this.getBelegeUserFile();
    } else if (this.documentType == 'Rechnung') {
      this.idCustom = 'vuePdfApp2';
      await this.getRechnungUserFile();
    }
  }

  async getRechnungUserFile() {
    var promiseAll: any = [];

    let payload = {};
    if (!!this.document.id) {
      payload = {
        documentType: this.documentType,
        recordId: this.document.recordID,
        dmsNr: '-', // filled for Invoices
        // TODO: (GSP 108) probably delete below fields
        // rechnungNr: this.document.belegnummer_Extern,
        // kreditorId: this.document.zrNummer_Lieferant, // Lieferantennummer = ZRNummer_Lieferant (ZrDocumentDm)
        // debitorID: this.document.zrNummer_Empfaenger, // Mitgliedsnummer = ZRNummer_Empfaenger (ZrDocumentDm)
      };
    } else if (!!this.invoice.id) {
      payload = {
        documentType: this.documentType,
        recordId: this.invoice.record_id || '', // (GSP-090) on 2023-11-22 ALL Invoices has `recordID` == NULL
        dmsNr: this.invoice.dms_nr,
        // TODO: (GSP 108) probably delete below fields
        // rechnungNr: this.invoice.externalDocumentReference,
        // kreditorId: this.invoice.senderNumber, // Lieferantennummer = senderNumber (InvoiceDm)
        // debitorID: this.invoice.receiverNumber, // Mitgliedsnummer = receiverNumber (InvoiceDm)
      };
    } else if (!!this.voucher.id) {
      payload = {
        documentType: this.documentType,
        recordId: this.invoice.record_id || '', // (GSP-090) on 2023-11-22 ALL Invoices has `recordID` == NULL
        dmsNr: this.voucher.dms_nr_invoice,
      };
    }

    // public string RechnungNr { get; set; }
    // public string KreditorId { get; set; } // Lieferantennummer = ZRNummer_Lieferant (ZrDocumentDm)
    // public string DebitorID { get; set; } // Mitgliedsnummer = ZRNummer_Empfaenger (ZrDocumentDm)

    await this.actionGetAzureFile(payload)
      .then((result: any) => {
        console.log('actionGetAzureFile result :>> ', result);
        if (result?.message?.toString().includes('failed')) {
          this.$emit('showBackupImage', 'Rechnung');
        } else {
          this.fileBlobUrl = URL.createObjectURL(result);
        }
      })
      .catch((err: any) => {
        logger.error(err);
      });

    Promise.all(promiseAll);
  }

  destroyed() {
    // revoke Blob URL to the browser cleared the memory from the downloaded file, when navigate away from the `document-preview` component
    URL.revokeObjectURL(this.fileBlobUrl);
  }

  beforeDestroy() {
    window.removeEventListener('scroll', this.hideMenuElement);
    window.removeEventListener('click', this.hideMenu);
  }

  hideMenu(ev: any) {
    this.isMenuOpened = false;
    let isMenuElement = false;
    let isComboboxElement = false;

    let element = document.getElementById(ev.target.id);
    let elementMenu = element?.closest('#menu');

    if (element == null) return;

    if (elementMenu != null && element != undefined) {
      isMenuElement = true;
    }

    if (
      ev.srcElement.className == 'v-list-item__title' ||
      ev.srcElement.className == 'v-list-item__content' ||
      (ev.srcElement.className as string).includes('v-list-item')
    )
      isComboboxElement = true;

    if (ev.path != undefined)
      ev.path.forEach((element: any) => {
        if (element.id == 'menu') isMenuElement = true;
      });
    if (!isMenuElement && !isComboboxElement) document.getElementById('menu')?.style.setProperty('display', 'none');
  }

  hideMenuElement() {
    document.getElementById('menu')?.style.setProperty('display', 'none');
  }

  private isMenuOpened: boolean = false;
  get showTooltip() {
    return !this.isMenuOpened;
  }

  //colorize log output
  //console.log('📕: error message');
  //console.log('📙: warning message');
  //console.log('📗: ok status message');
  //console.log('📘: action message');
  //console.log('📓: canceled status message');
  //console.log('📔: Or anything you like and want to recognize immediately by color');

  private documentsSearchPayload: any = {};

  async openHandler(pdfApp: any) {
    console.log('openHandler hello :>> ');
    //https://github.com/sandanat/vue-pdf-app/blob/06107045276b4e2f61fb1b05aa98f87eb42cac7a/src/pdfjs-dist/lib/test/unit/pdf_find_controller_spec.js
    //https://github.com/ocrmypdf/OCRmyPDF
    let words = this.documentsSearchPayload.chipDataArray?.map((element: any) => element.searchWord);
    let searchWords = words?.join(' ');

    if (searchWords?.length > 0) {
      //console.log('📗', searchWords);
      pdfApp.pdfViewer.findController.executeCommand('find', {
        query: searchWords,
        caseSensitive: false,
        entireWord: false,
        phraseSearch: false,
        findPrevious: undefined,
        highlightAll: true,
      });
    }

    const info = await pdfApp.pdfDocument.getMetadata().catch(console.error.bind(console));
    if (!info) return;
    const props = Object.keys(info.info);
    props.forEach((prop) => {
      const obj = {
        name: prop,
        value: info.info[prop],
      };
    });

    pdfApp.pdfDocument.getPage(1).then((page: any) => {
      if (page.rotate == 270) {
        this.originalWidth = page.view[3];
        this.originalHeight = page.view[2];
      } else {
        this.originalWidth = page.view[2];
        this.originalHeight = page.view[3];
      }
      console.log('original size: ' + this.originalWidth + ',' + this.originalHeight);
      this.image150DPIWidth = Math.floor(this.originalWidth * 2.084);
      this.image150DPIHeight = Math.floor(this.originalHeight * 2.084);
    });
  }

  private originalWidth: number = 0;
  private image150DPIWidth: number = 0;
  private originalHeight: number = 0;
  private image150DPIHeight: number = 0;
  private scale: number = 1;
  private scaleCanvasTo150DPI: number = 1;

  setEvents() {
    const scaleSelect: any = document.getElementById('scaleSelect');
    scaleSelect.addEventListener(
      'change',
      (e: any) => {
        this.scale = this.pdfApp.pdfViewer.currentScaleValue;
        console.log(this.scale);
      },
      false
    );
  }

  private pdfApp: any;

  afterCreatedHandler(pdfApp: any) {
    console.log('afterCreatedHandler');
    // this.pdfApp = pdfApp;
  }

  pagesRendered(pdfApp: any) {
    console.log('pagesRendered');
    // this.setEvents();
  }
}
